(function() {
  "use strcit";

  const urlAction = url_base + "acoes/app/produtos/carrinho.php";

  // Evento do botão para adicionar ao carrinho
  $("#form-add-cart").on("submit", function(e) {
    e.preventDefault();
    addToCart();
  });

  // Evento do botão para remover um item do carrinho
  $(".cart-rem").on("click", function(e) {
    e.preventDefault();
    remFromCart($(this).data("item"));
  });

  // Evento do botão para alterar a quantidade
  $(".cart-qtde").on("change", function() {
    const idItem = $(this)
    .attr("id")
    .replace(/\D/g, "");
    const itemQtde = Number($(this).val());
    updateQtdeCart(idItem, itemQtde);
  });

  // Evento do botão para finalizar o carrinho
  $("#form-cart-orcamento").on("submit", function(e) {
    e.preventDefault();
    enviaOrcamento();
  });

  // Retorna a quantidade de itens do carrinho
  function getCartTotal() {
    return $("tr[data-row]").length;
  }

  //Abre o modal unidades
  $(".btn-open-orcamento").on("click", function(e) {
    e.preventDefault();
    openModal("modal-unidades");
  });

  // Adiciona um produto ao carrinho
  function addToCart() {
    const form = $("#form-add-cart");
    const formObj = form.get(0);
    const submitBtn = $(".btn-add-cart");
    const dados = new FormData(formObj);
    $.ajax({
      url: form.attr("action"),
      type: form.attr("method"),
      data: dados,
      processData: false,
      cache: false,
      contentType: false,
      beforeSend: function() {
        showLoading();
        submitBtn.attr("disabled", true);
      },
      success: function(data) {
        console.log(data);
        submitBtn.attr("disabled", false);
        hideLoading();
        if (data == "ok") {
          location.href = url_base + "carrinho/add";
        } else {
          showAlert(
            "Já Adicionado",
            "Produto já esta adicionado ao carrinho.",
            "warning"
            );
        }
      },
      error: function(xhr, type, exception) {
        submitBtn.attr("disabled", false);
        hideLoading();
        showAlert("Erro", "Não foi possível realizar esta operação.", "error");
      }
    });
  }

  // Atualiza o cálculo no carrinho com base nas alterações
  function updateCart() {
    let total = 0;
    $("tr[data-row]").each(function() {
      const itemId = $(this).data("row");
      const qtde = Number($("#quantidade-" + itemId).val());
      const valor = $(this).data("valor");
      const subtotal = qtde * valor;
      $(this)
      .find(".cart-subtotal")
      .text("R$ " + formataMoeda(subtotal));
      total += subtotal;
    });
    $("#cart-total").text("R$ " + formataMoeda(total));
  }

  // Remove um produto do carrinho
  function remFromCart(idRem) {
    $.ajax({
      url: urlAction,
      type: "POST",
      data: {
        registro_id: idRem,
        acao: "delete"
      },
      beforeSend: function() {
        showLoading();
      },
      success: function(data) {
        //console.log(data);
        hideLoading();
        if (data == "ok") {
          $('[data-row="' + idRem + '"]').remove();
          if (getCartTotal() === 0) {
            location.reload();
          }
          updateCart();
          showAlert(
            "Sucesso",
            "O produto foi removido do carrinho com sucesso.",
            "success"
            );
        } else {
          showAlert(
            "Erro",
            "Não foi possível realizar esta operação.",
            "error"
            );
        }
      },
      error: function(xhr, type, exception) {
        hideLoading();
        showAlert("Erro", "Não foi possível realizar esta operação.", "error");
      }
    });
  }

  // Atualiza a quantidade do produto do carrinho
  function updateQtdeCart(idItem, itemQtde) {
    $.ajax({
      url: urlAction,
      type: "POST",
      data: {
        registro_id: idItem,
        quantidade: itemQtde,
        acao: "update"
      },
      beforeSend: function() {
        showLoading();
      },
      success: function(data) {
        //console.log(data);
        hideLoading();
        if (data == "ok") {
          updateCart();
        } else {
          showAlert(
            "Erro",
            "Não foi possível realizar esta operação.",
            "error"
            );
        }
      },
      error: function(xhr, type, exception) {
        hideLoading();
        showAlert("Erro", "Não foi possível realizar esta operação.", "error");
      }
    });
  }

  // Finaliza o carrinho e envia a solicitação de orçamento
  function enviaOrcamento() {
    const form = $("#form-cart-orcamento");
    const formObj = form.get(0);
    const submitBtn = $("#btn-cart-orcamento");
    const dados = new FormData(formObj);
    $.ajax({
      url: form.attr("action"),
      type: form.attr("method"),
      data: dados,
      processData: false,
      cache: false,
      contentType: false,
      beforeSend: function() {
        showLoading();
        submitBtn.attr("disabled", true);
      },
      success: function(data) {
        //console.log(data);
        submitBtn.attr("disabled", false);
        hideLoading();
        if (data == "ok") {
          location.href = url_base + "minha-conta/pedidos?rg-session=true";
        } else {
          showAlert(
            "Erro",
            "Não foi possível realizar esta operação.",
            "error"
            );
        }
      },
      error: function(xhr, type, exception) {
        submitBtn.attr("disabled", false);
        hideLoading();
        showAlert("Erro", "Não foi possível realizar esta operação.", "error");
      }
    });
  }
})();
