/* =================== HEADER FULL =================== */
/*jQuery(document).ready(function($){
  const header = $('.header-full');
  if (header.length > 0) {
    function toggleHeaderFull() {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > 100) {
        header.addClass('active');
      } else {
        header.removeClass('active');
      }
    }
    toggleHeaderFull();
    $(window).on('scroll', debounce(function(){
      toggleHeaderFull();        
    }, 10));
  }
});*/


/*var scrollTop = $(window).scrollTop();
if(isMobileMaior(1200)){
  $(window).on('scroll', debounce(function () {
    if ($(this).scrollTop() > scrollTop) {
      $('.fdx-menu.full').addClass('hide-menu');
      $('.header').addClass('sombra');
    } else {
      $('.fdx-menu.full').removeClass('hide-menu');
      $('.header').removeClass('sombra');
    }
    scrollTop = $(this).scrollTop();
  }, 100));
}*/
